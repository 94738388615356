import { IntegrationFormDeleteButton } from '@components/atoms/IntegrationFormDeleteButton/IntegrationFormDeleteButton';
import { IntegrationStates } from '@components/pages/Integrations';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Toggle,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import { getIntegrationErrorMessage } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export interface WooCommerceIntegrationProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: () => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  isReferenceNumberRequired?: boolean;
}

export const WooCommerceIntegrationForm: React.FC<WooCommerceIntegrationProps> = ({
  integration,
  validator,
  deleteIntegrationResource,
  onSubmit,
  onClose,
  onDelete,
  isEdit,
  isNew,
  isReferenceNumberRequired,
  isBusy,
  intl,
  onWillMount,
  onWillUnmount,
  error,
}) => {
  const forceUpdate = useForceUpdate();

  const [integrationState, setIntegrationState] = useState<IntegrationStates>({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    clientId: integration ? integration.clientId : '',
    clientSecret: integration ? integration.clientSecret : '',
    shopLink: integration ? integration.shopLink : '',
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : undefined,
    shouldSyncMissingData: false,
    shouldSyncMissingOrders: false,
    shouldSyncMissingProducts: false,
    type: IntegrationType.WooCommerce,
    isCoDActive: integration ? integration.isCoDActive : false,
    cashKey: integration ? integration.cashKey : '',
    creditCardKey: integration ? integration.creditCardKey : '',
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
  });

  const [initialIntegrationState, setInitialIntegrationState] = useState(
    integration ? integration.state === IntegrationState.Active : true
  );

  useEffect(() => {
    validator.registerSchema(wooCommerceIntegrationFormSchemaFields());

    onWillMount && onWillMount();

    return () => {
      validator.clearErrors();
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const handleCheckboxChange = (key: 'isCoDActive' | 'isActive') => (e: any) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.checked,
      cashKey: '',
      creditCardKey: '',
    });
  };

  const handleInputChange = (key: keyof CreateIntegrationCommand | keyof UpdateIntegrationCommand) => (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.value,
    });
  };

  const handleBlur = (key: string) => () => {
    validator.validate(key, integrationState[key]);
    forceUpdate();
  };

  const isEmpty = () => {
    return (
      integrationState.integrationName === '' ||
      integrationState.clientId === '' ||
      integrationState.clientSecret === '' ||
      integrationState.shopLink === '' ||
      (isReferenceNumberRequired === true && integrationState.referenceNumberPrefix === '') ||
      (integrationState.isCoDActive && (integrationState.cashKey === '' || integrationState.creditCardKey === ''))
    );
  };

  const handleSubmit = (e?: React.SyntheticEvent<HTMLFormElement>) => {
    const integration = { ...integrationState };

    e && e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }

    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integration[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      forceUpdate();
      return;
    }
    if (!integration.referenceNumberPrefix) {
      delete integration.referenceNumberPrefix;
    }

    onSubmit(integration, initialIntegrationState);
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }
    onClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }

      onDelete();
    }
  };

  const handleIsActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isActive: !!e.currentTarget.checked,
    });
  };

  const wooCommerceIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string(),
      isActive: Yup.boolean(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      clientId: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ConsumerKey`]}`),
      clientSecret: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ConsumerSecret`]}`),
      shopLink: Yup.string()
        .url(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Url`]}`)
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ShopLink`]}`),
      referenceNumberPrefix,
      type: Yup.string().required(),
      shouldSyncMissingData: Yup.boolean(),
      isCoDActive: Yup.boolean(),
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      {error?.errors && (
        <Alert variant="danger" isOpen mb="11" flexDirection="column" alignItems="flex-start">
          {error?.errors.map(error => (
            <AlertDescription>
              {' '}
              {getIntegrationErrorMessage(error.errorCode, error.property, COMPONENT_INTL_KEY, intl)}
            </AlertDescription>
          ))}
        </Alert>
      )}
      {isEdit && integration && (
        <FormControl
          size="small"
          isInline
          mb="22"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
          <Toggle isChecked={integrationState.isActive || false} onChange={handleIsActiveChange} />
        </FormControl>
      )}
      <Box width={1}>
        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInvalid={!!validator.getErrorIntent('integrationName')}
          isInline
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
            value={integrationState.integrationName}
            onChange={handleInputChange('integrationName')}
            onBlur={handleBlur('integrationName')}
          />
          <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isInline
          isInvalid={!!validator.getErrorIntent('clientId')}
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ConsumerKey`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ConsumerKey`]}
            value={integrationState.clientId}
            onChange={handleInputChange('clientId')}
            onBlur={handleBlur('clientId')}
          />
          <FormErrorMessage>{validator.getErrorIntent('clientId')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInline
          isInvalid={!!validator.getErrorIntent('clientSecret')}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ConsumerSecret`]}*</FormLabel>
          <Input
            type="password"
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ConsumerSecret`]}
            value={integrationState.clientSecret}
            onChange={handleInputChange('clientSecret')}
            onBlur={handleBlur('clientSecret')}
          />
          <FormErrorMessage>{validator.getErrorIntent('clientSecret')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInvalid={!!validator.getErrorIntent('shopLink')}
          isInline
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ShopLink`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ShopLink`]}
            value={integrationState.shopLink}
            onChange={handleInputChange('shopLink')}
            onBlur={handleBlur('shopLink')}
          />
          <FormErrorMessage>{validator.getErrorIntent('shopLink')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
          isInline
          isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
            value={integrationState.referenceNumberPrefix}
            onChange={handleInputChange('referenceNumberPrefix')}
            onBlur={handleBlur('referenceNumberPrefix')}
          />
          <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          mt="22"
          mb="11"
          isInline
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashOnDelivery`]}</FormLabel>
          <Toggle isChecked={integrationState.isCoDActive} onChange={handleCheckboxChange('isCoDActive')} />
        </FormControl>
        {integrationState.isCoDActive && (
          <>
            <FormControl
              size="small"
              isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
              isInline
              isInvalid={!!validator.getErrorIntent('cashKey')}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashKey`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CashKey`]}
                value={integrationState.cashKey}
                onChange={handleInputChange('cashKey')}
                onBlur={handleBlur('cashKey')}
              />
              <FormErrorMessage>{validator.getErrorIntent('cashKey')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl
              size="small"
              isInline
              isInvalid={!!validator.getErrorIntent('creditCardKey')}
              isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CreditCardKey`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CreditCardKey`]}
                value={integrationState.creditCardKey}
                onChange={handleInputChange('creditCardKey')}
                onBlur={handleBlur('creditCardKey')}
              />
              <FormErrorMessage>{validator.getErrorIntent('creditCardKey')?.text}</FormErrorMessage>
            </FormControl>
            <FormErrorMessage independent mb="11">
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.WooCommerceCODWarning`]}
            </FormErrorMessage>
          </>
        )}
        <FormErrorMessage independent>
          {intl.messages[`${COMPONENT_INTL_KEY}.Error.${error ? error.message : ''}`]}
        </FormErrorMessage>
      </Box>
      <Flex mt="16">
        <Button
          size="small"
          type="button"
          kind="outline"
          variant="dark"
          disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          onClick={() => handleClose()}
          mr="11"
          width="full"
          maxWidth="118px"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
        </Button>
        {isEdit && (
          <IntegrationFormDeleteButton
            onClick={handleDelete}
            isBusy={isBusy}
            isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
            intl={intl}
            tooltipKey={`${COMPONENT_INTL_KEY}.Form.CancelInfo`}
          />
        )}
        <Button
          size="small"
          type="submit"
          disabled={
            isBusy ||
            (deleteIntegrationResource && deleteIntegrationResource.isBusy) ||
            validator.hasErrors() ||
            isEmpty()
          }
          isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          width="full"
          variant="success"
          ml="11"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
        </Button>
      </Flex>
    </form>
  );
};
