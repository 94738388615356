import { Button, Tooltip } from '@oplog/express';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

export interface IntegrationFormDeleteButtonProps {
  onClick: () => void;
  isBusy?: boolean;
  isLoading?: boolean;
  intl: InjectedIntl;
  tooltipKey: string;
}

export const IntegrationFormDeleteButton: React.FC<IntegrationFormDeleteButtonProps> = ({
  onClick,
  isBusy,
  isLoading,
  intl,
  tooltipKey,
}) => {
  return (
    <Tooltip
      action={['hover']}
      placement="top"
      content={intl.messages[tooltipKey]}
    >
      <Button
        size="small"
        variant="danger"
        disabled={isBusy}
        isLoading={isLoading}
        onClick={onClick}
        width="full"
      >
        {intl.messages['Integrations.IntegrationInfo.Form.Delete']}
      </Button>
    </Tooltip>
  );
};