import { IntegrationFormDeleteButton } from '@components/atoms/IntegrationFormDeleteButton/IntegrationFormDeleteButton';
import { IntegrationStates } from '@components/pages/Integrations';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Toggle,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import { getIntegrationErrorMessage } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export interface OrderDeskIntegrationProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: () => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  isReferenceNumberRequired?: boolean;
}

export interface OrderDeskIntegrationState {
  initialIntegrationState?: boolean;
  integration: UpdateIntegrationCommand & CreateIntegrationCommand;
}

export const OrderDeskIntegrationForm: React.FC<OrderDeskIntegrationProps> = ({
  integration,
  validator,
  deleteIntegrationResource,
  onSubmit,
  onClose,
  onDelete,
  isEdit,
  isNew,
  isReferenceNumberRequired,
  isBusy,
  intl,
  onWillMount,
  onWillUnmount,
  error,
}) => {
  const forceUpdate = useForceUpdate();

  const [initialIntegrationState, setInitialIntegrationState] = useState(
    integration ? integration.state === IntegrationState.Active : true
  );

  const isEmpty = () => {
    return (
      integrationState.integrationName === '' ||
      integrationState.apiUserName === '' ||
      integrationState.apiPassword === '' ||
      integrationState.productBarcodeKeyField === '' ||
      (isReferenceNumberRequired === true && integrationState.referenceNumberPrefix === '')
    );
  };

  const [integrationState, setIntegrationState] = useState<IntegrationStates>({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    apiUserName: integration ? integration.apiUserName : '',
    apiPassword: integration ? integration.apiPassword : '',
    shopLink: integration ? integration.shopLink : '',
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : undefined,
    isCoDActive: integration ? integration.isCoDActive : false,
    cashKey: integration ? integration.cashKey : '',
    creditCardKey: integration ? integration.creditCardKey : '',
    isSubProductActive: integration ? integration.isSubProductActive : false,
    shouldSyncMissingData: false,
    shouldSyncMissingOrders: false,
    shouldSyncMissingProducts: false,
    type: IntegrationType.OrderDesk,
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
    productBarcodeKeyField: integration ? integration.productBarcodeKeyField : '',
  });

  useEffect(() => {
    validator.registerSchema(OrderDeskIntegrationFormSchemaFields());
    onWillMount && onWillMount();

    return () => {
      onWillUnmount && onWillUnmount();
      validator.clearErrors();
    };
  }, []);

  const handleInputChange = (key: keyof CreateIntegrationCommand | keyof UpdateIntegrationCommand) => (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.value,
    });
  };

  const handleBlur = (key: string) => () => {
    validator.validate(key, integrationState[key]);
    forceUpdate();
  };

  const handleSubmit = (e?: React.SyntheticEvent<HTMLFormElement>) => {
    const integration = { ...integrationState };

    e && e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }

    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integration[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      forceUpdate();
      return;
    }
    if (!integration.referenceNumberPrefix) {
      delete integration.referenceNumberPrefix;
    }

    onSubmit(integration, initialIntegrationState);
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }
    onClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }

      onDelete();
    }
  };

  const handleIsActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isActive: !!e.currentTarget.checked,
    });
  };

  const OrderDeskIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string(),
      isActive: Yup.boolean(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      apiUserName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ApiUsername`]}`),
      apiPassword: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ApiPassword`]}`),
      referenceNumberPrefix,
      isTestEnvironment: Yup.boolean(),
    };
  };

  return (
    <Box>
      {error?.errors && (
        <Alert variant="danger" isOpen mb="11" flexDirection="column" alignItems="flex-start">
          {error?.errors.map((error, index) => (
            <AlertDescription key={index}>
              {' '}
              {getIntegrationErrorMessage(error.errorCode, error.property, COMPONENT_INTL_KEY, intl)}
            </AlertDescription>
          ))}
        </Alert>
      )}
      {isEdit && integration && (
        <FormControl
          size="small"
          isInline
          mb="22"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
          <Toggle isChecked={integrationState.isActive || false} onChange={handleIsActiveChange} />
        </FormControl>
      )}
      <Box width={1}>
        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInvalid={!!validator.getErrorIntent('integrationName')}
          isInline
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
            value={integrationState.integrationName}
            onChange={handleInputChange('integrationName')}
            onBlur={handleBlur('integrationName')}
          />
          <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isInline
          isInvalid={!!validator.getErrorIntent('storeId')}
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.StoreId`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.StoreId`]}
            value={integrationState.apiUserName}
            onChange={handleInputChange('apiUserName')}
            onBlur={handleBlur('storeId')}
          />
          <FormErrorMessage>{validator.getErrorIntent('storeId')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInline
          isInvalid={!!validator.getErrorIntent('apiPassword')}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ApiPassword`]}*</FormLabel>
          <Input
            type="password"
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ApiPassword`]}
            value={integrationState.apiPassword}
            onChange={handleInputChange('apiPassword')}
            onBlur={handleBlur('apiPassword')}
          />
          <FormErrorMessage>{validator.getErrorIntent('apiPassword')?.text}</FormErrorMessage>
        </FormControl>
        <FormControl
          size="small"
          isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
          isInline
          isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
            value={integrationState.referenceNumberPrefix}
            onChange={handleInputChange('referenceNumberPrefix')}
            onBlur={handleBlur('referenceNumberPrefix')}
          />
          <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
        </FormControl>
        <FormErrorMessage independent>
          {intl.messages[`${COMPONENT_INTL_KEY}.Error.${error ? error.message : ''}`]}
        </FormErrorMessage>

        <FormControl
          size="small"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          isInline
          isInvalid={!!validator.getErrorIntent('productBarcodeKeyField')}
          mb="11"
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ProductBarcodeKeyField`]}*</FormLabel>
          <Input
            placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ProductBarcodeKeyField`]}
            value={integrationState.productBarcodeKeyField}
            onChange={handleInputChange('productBarcodeKeyField')}
            onBlur={handleBlur('productBarcodeKeyField')}
          />
          <FormErrorMessage>{validator.getErrorIntent('productBarcodeKeyField')?.text}</FormErrorMessage>
        </FormControl>
        <FormErrorMessage independent mb="11">
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.ProductBarcodeKeyFieldWarning`]}
        </FormErrorMessage>

      </Box>
      <Flex mt="16">
        <Button
          size="small"
          type="button"
          kind="outline"
          variant="dark"
          disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          onClick={handleClose}
          mr="11"
          width="full"
          maxWidth="118px"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
        </Button>
        {isEdit && (
          <IntegrationFormDeleteButton
            onClick={handleDelete}
            isBusy={isBusy}
            isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
            intl={intl}
            tooltipKey={`${COMPONENT_INTL_KEY}.Form.CancelInfo`}
          />
        )}
        <Button
          size="small"
          type="submit"
          disabled={
            isBusy ||
            (deleteIntegrationResource && deleteIntegrationResource.isBusy) ||
            isEmpty() ||
            validator.hasErrors()
          }
          isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          width="full"
          variant="success"
          ml="11"
          onClick={() => handleSubmit()}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
        </Button>
      </Flex>
    </Box>
  );
};
