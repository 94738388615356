import { IntegrationFormDeleteButton } from '@components/atoms/IntegrationFormDeleteButton/IntegrationFormDeleteButton';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Image,
  Input,
  Popover,
  Toggle
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreatedIntegrationOutputDTO,
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export interface EntegraIntegrationFormProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: (isSuccess?: boolean) => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  response?: CreatedIntegrationOutputDTO;
  isReferenceNumberRequired?: boolean;
}

export const EntegraIntegrationForm: React.FC<EntegraIntegrationFormProps> = ({
  validator,
  intl,
  integration,
  deleteIntegrationResource,
  isReferenceNumberRequired,
  isBusy,
  onClose,
  onDelete,
  isNew,
  onSubmit,
  isEdit,
  error,
}: EntegraIntegrationFormProps) => {
  const [credentials, setCredentials] = useState<CreatedIntegrationOutputDTO>({
    id: '',
    integrationCredentials: {},
  });

  const [initialIntegrationState, setInitialIntegrationState] = React.useState<boolean>(
    integration ? integration.state === IntegrationState.Active : true
  );

  const forceUpdate = useForceUpdate();

  const [integrationState, setIntegrationState] = useState({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : '',
    shouldSyncMissingData: false,
    type: IntegrationType.Entegra,
    tenantSecret: integration ? integration.tenantSecret : '',
    isProductSyncActive: integration ? integration.isProductSyncActive : false,
    isMarketplaceSyncActive: integration ? integration.isMarketplaceSyncActive : false,
    isWebsiteSyncActive: integration ? integration.isWebsiteSyncActive : false,
    isSendOrdersByOplogActive: integration ? integration.isSendOrdersByOplogActive : false,
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
  });

  useEffect(() => {
    validator.registerSchema(entegraIntegrationFormSchemaFields());
    return () => {
      validator.clearErrors();
    };
  }, []);

  const entegraIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string().required(),
      isActive: Yup.boolean().required(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      tenantSecret: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.TenantSecret`]}`),
      isProductSyncActive: Yup.boolean().required(),
      isMarketplaceSyncActive: Yup.boolean().required(),
      isWebsiteSyncActive: Yup.boolean().required(),
      isSendOrdersByOplogActive: Yup.boolean().required(),
      type: Yup.string().required(),
      shouldSyncMissingData: Yup.boolean().required(),
      referenceNumberPrefix,
    };
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }
    onClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }
      onDelete();
    }
  };

  const handleBlur = (key: string) => {
    validator.validate(key, integrationState[key]);
  };

  const handleIsActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isActive: !!e.currentTarget.checked,
    });
  };

  const handleIsProductSyncActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isProductSyncActive: !!e.currentTarget.checked,
    });
  };

  const handleIsMarketplaceSyncActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isMarketplaceSyncActive: !!e.currentTarget.checked,
    });
  };

  const handleIsWebsiteSyncActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isWebsiteSyncActive: !!e.currentTarget.checked,
      ...((!!e.currentTarget.checked === false) && { isSendOrdersByOplogActive: !!e.currentTarget.checked })
    });
  };

  const handleIsSendOrdersByOplogActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isSendOrdersByOplogActive: !!e.currentTarget.checked,
    });
  };

  const isEmpty = () => {
    return integrationState.integrationName === '' || integrationState.tenantSecret === '';
  };

  const handleInputChange = (key: string, value: any) => {
    setIntegrationState({
      ...integrationState,
      [key]: value,
    });
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }
    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integrationState[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      forceUpdate();
      return;
    }

    onSubmit(integrationState, initialIntegrationState);
  };

  const errorKey = error && error.errors && error.errors.length > 0 ? error.errors[0].errorCode : error?.message;

  return (
    <form onSubmit={handleSubmit}>
      {isEdit && (
        <>
          <FormControl
            size="small"
            isInline
            mb="22"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
            <Toggle isChecked={integrationState.isActive || false} onChange={handleIsActiveChange} />
          </FormControl>
          <FormControl
            size="small"
            isInline
            mb="11"
          >
            <FormLabel>{"INTEGRATION ID"}</FormLabel>
            <Input
              placeholder={"INTEGRATION ID"}
              value={integrationState.id}
              isReadOnly
            />
          </FormControl>
        </>

      )}
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('integrationName')}
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        isInline
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
          value={integrationState.integrationName}
          onChange={(e: any) => handleInputChange('integrationName', e.currentTarget.value)}
          onBlur={() => handleBlur('integrationName')}
        />
        <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
        <FormErrorMessage>{error ? error.message : ''}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
        isInline
        isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
          value={integrationState.referenceNumberPrefix}
          onChange={(e: any) => handleInputChange('referenceNumberPrefix', e.currentTarget.value)}
          onBlur={handleBlur('referenceNumberPrefix')}
        />
        <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('tenantSecret')}
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        isInline
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.TenantSecret`]}</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.TenantSecret`]}
          value={integrationState.tenantSecret}
          onChange={(e: any) => handleInputChange('tenantSecret', e.currentTarget.value)}
          onBlur={() => handleBlur('tenantSecret')}
        />
        <FormErrorMessage>{validator.getErrorIntent('tenantSecret')?.text}</FormErrorMessage>
        <FormErrorMessage>{error ? error.message : ''}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInline
        mb="22"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsProductSyncActiveInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsProductSyncActive`]}</FormLabel>
        <Toggle isChecked={integrationState.isProductSyncActive || false} onChange={handleIsProductSyncActiveChange} />
      </FormControl>
      <FormControl
        size="small"
        isInline
        mb="22"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsMarketplaceSyncActiveInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsMarketplaceSyncActive`]}</FormLabel>
        <Toggle
          isChecked={integrationState.isMarketplaceSyncActive || false}
          onChange={handleIsMarketplaceSyncActiveChange}
        />
      </FormControl>
      <FormControl
        size="small"
        isInline
        mb="22"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsWebsiteSyncActiveInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsWebsiteSyncActive`]}</FormLabel>
        <Toggle isChecked={integrationState.isWebsiteSyncActive || false} onChange={handleIsWebsiteSyncActiveChange} />
      </FormControl>
      <FormControl
        size="small"
        isInline
        mb="22"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy) || (!integrationState.isWebsiteSyncActive)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsSendOrdersByOplogActiveInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsSendOrdersByOplogActive`]}</FormLabel>
        <Toggle isChecked={integrationState.isSendOrdersByOplogActive || false} onChange={handleIsSendOrdersByOplogActiveChange} />
      </FormControl>

      <FormErrorMessage independent>{intl.messages[`${COMPONENT_INTL_KEY}.Error.${errorKey}`]}</FormErrorMessage>
      <Flex mt="16">
        <Button
          size="small"
          type="button"
          kind="outline"
          variant="dark"
          disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          onClick={handleClose}
          mr="11"
          width="full"
          maxWidth="118px"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
        </Button>
        {isEdit && (
          <IntegrationFormDeleteButton
            onClick={handleDelete}
            isBusy={isBusy}
            isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
            intl={intl}
            tooltipKey={`${COMPONENT_INTL_KEY}.Form.CancelInfo`}
          />
        )}
        <Button
          size="small"
          type="submit"
          disabled={
            isBusy ||
            (deleteIntegrationResource && deleteIntegrationResource.isBusy) ||
            validator.hasErrors() ||
            isEmpty()
          }
          isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          width="full"
          ml="11"
          variant="success"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
        </Button>
      </Flex>
    </form>
  );
};
